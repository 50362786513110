import store from '@/store'

export const useHolidays = () => {

  const submitValidatedHoliday = (holiday) => {
    return new Promise((resolve, reject) => {
      if ('id' in holiday) {
        updateHoliday(holiday)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewHoliday(holiday)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewHoliday = (holiday) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event3/addEvent', holiday)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateHoliday = (holiday) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event3/updateEvent', holiday)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeHoliday = (holiday) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event3/removeEvent', holiday)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedHoliday,
    removeHoliday,
  }
}
<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$t('Holidays')"
        :items="events3"
        :items-context="events3Context"
        :available-filters="store.getters['event2/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"
        :allow-view-windows="false"
    >
      <template #listView_cell_startDate="{item}">
        {{ item.startDate|moment('LL') }}
        <span v-if="isMorning(item.startDate)">{{ $t('startMorning') }}</span>
        <span v-else>{{ $t('startAfternoon') }}</span>
      </template>

      <template #listView_cell_endDate="{item}">
        {{ item.endDate|moment('LL') }}
        <span v-if="isMorning(item.endDate)">{{ $t('endMorning') }}</span>
        <span v-else>{{ $t('endAfternoon') }}</span>
      </template>

      <template #listView_cell_isValidated="{item}">
        <b-badge
            pill
            :variant="'light-'+(item.isValidated?'success':(item.isValidated==false?'danger':'secondary'))"
            class="text-capitalize"
        >
          <icon :icon="getIcon(item)"/>
        </b-badge>
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Validate-->
        <button-validate
            v-if="$can('manage', 'Holidays')"
            @click.native.stop="validateHoliday(item)"
            v-b-tooltip.hover.left="$t('validate')"
            :disabled="item.isValidated == true"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          UnValidate-->
        <button-delete
            v-if="$can('manage', 'Holidays')"
            @click.native.stop="declineHoliday(item)"
            v-b-tooltip.hover.left="capitalize($t('cancel'))"
            :disabled="item.isValidated != null"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-if="$can('manage', 'Holidays')"
            @click.native.stop="removeHolidayAlert(item)"
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            :disabled="item.isValidated != null"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>

    </base-template>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'
import { useHolidays } from './useHolidays'

import useAPI from '../../../utils/useAPI'
import store from '../../../store'
import moment from 'moment'

import BaseTemplate from '../../../components/base3/Base.vue'
import ButtonRemove from '../../../components/button/Remove.vue'
import ButtonValidate from '../../../components/button/Validate.vue'
import ButtonDelete from '../../../components/button/Delete.vue'

export default {
  components: { ButtonDelete, ButtonValidate, ButtonRemove, BaseTemplate },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const module = ref('holidays')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const currentHoliday = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { events3, events3Context } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      fetchEvents3(val)
          .then(() => {
            if (!isAutocompleteLoaded.value) {
              loadInit()
            }
          })
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchEvents3,
      fetchAutocompleteEmployees
    } = useAPI()

    const { submitValidatedHoliday, removeHoliday } = useHolidays()

    const loadInit = () => {
      fetchAutocompleteEmployees()

      isAutocompleteLoaded.value = true
    }

    const resetHoliday = () => {
      currentHoliday.value = JSON.parse(JSON.stringify({}))
    }

    const isMorning = (date) => {
      if (moment(date).format('HH') <= 12) {
        return true
      } else {
        return false
      }
    }

    const getIcon = (event) => {
      if (event.isValidated) {
        return 'check'
      } else if (event.isValidated == false) {
        return 'times'
      } else {
        return 'stopwatch'
      }
    }

    const validateHoliday = (holiday) => {
      store.dispatch('event2/getEvent', holiday.id)
          .then(response => {
            response.isValidated = true

            submitValidatedHoliday(response)
                .then(() => {
                  resetHoliday()
                })
          })

    }

    const declineHoliday = (holiday) => {
      store.dispatch('event2/getEvent', holiday.id)
          .then(response => {
            response.isValidated = false

            submitValidatedHoliday(response)
                .then(() => {
                  resetHoliday()
                })
          })
    }

    const removeHolidayLocal = (holiday) => {
      removeHoliday(holiday)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetHoliday()

    store.dispatch('moduleView/getModuleViews')
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            page: 1,
            sorts: currentView.sorts,
          }

        })

    return {
      // Components
      capitalize,

      // Data
      module,
      payload,
      isReady,

      currentHoliday,

      // Computed
      events3,
      events3Context,


      // Methods
      isMorning,
      getIcon,
      validateHoliday,
      declineHoliday,
      removeHolidayLocal,
    }
  },
  data () {
    return {}
  },
  computed: {
    store () {
      return store
    }
  },
  watch: {},
  methods: {
    removeHolidayAlert (employee) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theHoliday') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeHolidayLocal(employee)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>
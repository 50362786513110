import { render, staticRenderFns } from "./Holisays3.vue?vue&type=template&id=c435e5da&scoped=true&"
import script from "./Holisays3.vue?vue&type=script&lang=js&"
export * from "./Holisays3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c435e5da",
  null
  
)

export default component.exports